import { useState, useEffect } from 'react';

const COOKIE_CONSENT_KEY = 'cookie_consent_settings';

export const useCookieConsent = () => {
  const [cookieConsent, setCookieConsent] = useState({
    necessary: true,
    analytics: false,
    thirdParty: false,
    hasConsented: false
  });

  const [showConsentModal, setShowConsentModal] = useState(false);

  useEffect(() => {
    // Check for existing consent settings
    const storedConsent = localStorage.getItem(COOKIE_CONSENT_KEY);
    if (storedConsent) {
      setCookieConsent(JSON.parse(storedConsent));
    } else {
      setShowConsentModal(true);
    }
  }, []);

  const setAnalyticsCookies = (enabled) => {
    if (enabled) {
      // Enable analytics cookies
      document.cookie = `analytics_enabled=true;path=/;max-age=${30 * 24 * 60 * 60}`;
    } else {
      // Remove analytics cookies
      document.cookie = 'analytics_enabled=;path=/;max-age=0';
      // Remove other analytics related cookies if needed
      document.cookie = '_ga=;path=/;max-age=0;domain=' + window.location.hostname;
      document.cookie = '_gat=;path=/;max-age=0;domain=' + window.location.hostname;
      document.cookie = '_gid=;path=/;max-age=0;domain=' + window.location.hostname;
    }
  };

  const setThirdPartyCookies = (enabled) => {
    if (enabled) {
      // Enable third party cookies
      document.cookie = `third_party_enabled=true;path=/;max-age=${30 * 24 * 60 * 60}`;
    } else {
      // Remove third party cookies
      document.cookie = 'third_party_enabled=;path=/;max-age=0';
      // Add removal of specific third party cookies if needed
    }
  };

  const saveConsent = (settings) => {
    const newSettings = {
      ...settings,
      necessary: true, // Always true
      hasConsented: true
    };

    // Save to localStorage
    localStorage.setItem(COOKIE_CONSENT_KEY, JSON.stringify(newSettings));
    setCookieConsent(newSettings);
    setShowConsentModal(false);

    // Apply cookie settings
    setAnalyticsCookies(settings.analytics);
    setThirdPartyCookies(settings.thirdParty);
  };

  const acceptAll = () => {
    saveConsent({
      necessary: true,
      analytics: true,
      thirdParty: true
    });
  };

  const rejectAll = () => {
    saveConsent({
      necessary: true,
      analytics: false,
      thirdParty: false
    });
  };

  return {
    cookieConsent,
    showConsentModal,
    saveConsent,
    acceptAll,
    rejectAll
  };
};