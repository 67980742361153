export const nl = {
  nav: {
    title: 'Laat het geld zien A.I.',
    home: 'Start',
    login: 'Inloggen'
  },
  hero: {
    title: 'Laat het geld zien A.I.',
    subtitle: 'De toekomst van realtime AI-gedreven financiële analyse'
  },
  features: {
    title: 'Uw toegang tot geavanceerde financiële analyses',
    realtime: 'Overnight Gap Voorspelling',
    personalizedFeed: 'Detecteer koersbewegingen tijdens de nacht door de combinatie van AI en nieuwsanalyse.',
    ai: 'Realtime Nieuwsintegratie',
    aiRecommendations: 'Pas uw strategieën direct aan op actuele gebeurtenissen en politieke ontwikkelingen.',
    markets: 'Benut Marktpsychologie',
    aiNewsAnalytics: 'Integreer sentiment en psychologische trends voor beter onderbouwde handelsbeslissingen.',
    news: 'Adaptieve AI-modellen',
    influence: 'Gebruik machine learning die zich live aanpast aan markt- en nieuwsveranderingen.'
},
  description: {
    intro: 'Onze app maakt gebruik van AI-technologie, financiële expertise en geavanceerde technische indicatoren om nauwkeurige realtime-inzichten te bieden. Met een AI-chatbot die altijd beschikbaar is, krijgt u professioneel advies op maat.',
    points: [
      'Realtime toegang tot aandelenkoersen en marktanalyse',
      'Uitgebreide technische indicatoren: MACD, RSI, Bollinger Bands en meer',
      'AI-gestuurde sentimentanalyse van nieuws en social media',
      'Voorspellende modellen aangedreven door machine learning voor nauwkeurige trendvoorspellingen',
      'AI-chatbotondersteuning voor gepersonaliseerde investeringsstrategieën en deskundig advies'
    ],
    emphasis: 'Blijf voorop met Laat het geld zien A.I., die datagestuurde inzichten en realtime voorspellingen combineert tot een krachtige investeringstool.'
  },
  pricing: {
    title: 'Flexibele abonnementen voor uw financiële doelen',
    monthly: '/maand',
    cta: 'Begin nu',
    freeTrial: {
      title: 'Gratis Proefversie',
      price: '0 €',
      features: [
        'Basis toegang tot marktgegevens',
        'Beperkte technische analyse',
        'Dagelijkse marktupdates'
      ]
    },
    standardSubscription: {
      title: 'Standaard Abonnement',
      price: '100 €/maand',
      features: [
        'Uitgebreide marktgegevens en realtime analyse',
        'AI-gebaseerde aanbevelingen en meldingen',
        'Gepersonaliseerde inzichten en rapporten'
      ]
    },
    proSubscription: {
      title: 'Pro Abonnement',
      price: '500 €/maand',
      features: [
        'Volledige toegang tot voorspellende analyse en realtime gegevens',
        'Geavanceerde sentiment- en trendanalyse',
        '24/7 persoonlijke AI-adviseur'
      ]
    }
  },
  auth: {
    username: 'Gebruikersnaam',
    password: 'Wachtwoord', 
    email: 'E-mail',
    confirmPassword: 'Wachtwoord bevestigen',
    loginButton: 'Inloggen',
    registerButton: 'Registreren',
    noAccount: 'Nog geen account? Registreren',
    hasAccount: 'Al een account? Inloggen',
    errors: {
      loginFailed: 'Inloggen mislukt: ',
      loginError: 'Er is een fout opgetreden tijdens het inloggen',
      registrationSuccess: 'Registratie succesvol. Log nu in.',
      registrationFailed: 'Registratie mislukt: ',
      registrationError: 'Er is een fout opgetreden tijdens de registratie',
      passwordMatch: 'Wachtwoorden komen niet overeen'
    }
  },
  cookies: {
    title: 'Cookie-instellingen',
    description: 'Deze website gebruikt cookies om bezoekers een optimale gebruikerservaring te bieden. Bepaalde inhoud van derden wordt alleen weergegeven als de bijbehorende optie is ingeschakeld. Gegevensverwerking kan dan ook in een derde land plaatsvinden. Meer informatie hierover vindt u in het privacybeleid.',
    necessary: {
      title: 'Technisch noodzakelijke cookies',
      description: 'Vereist voor basisfunctionaliteit van de website'
    },
    analytics: {
      title: 'Analytische cookies',
      description: 'Voor analyse van websitegebruik'
    },
    thirdParty: {
      title: 'Inhoud van derden',
      description: 'Voor externe inhoud en functies'
    },
    acceptAll: 'Alles accepteren',
    save: 'Opslaan'
  }
};