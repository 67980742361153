import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Navigation from './components/Navigation.jsx';
import HomePage from './pages/HomePage.jsx';
import LoginRegister from './components/LoginRegister.jsx';
import CookieConsent from './components/CookieConsent.jsx';
import { useCookieConsent } from './hooks/useCookieConsent.js';

const App = () => {
  const [showLoginModal, setShowLoginModal] = useState(false);
  const { cookieConsent, showConsentModal, saveConsent, acceptAll } = useCookieConsent();

  useEffect(() => {
    // Check URL parameters for login requirement
    const params = new URLSearchParams(window.location.search);
    if (params.get('requireLogin') === 'true') {
      setShowLoginModal(true);
      // Clean URL
      window.history.replaceState({}, document.title, window.location.pathname);
    }
  }, []);

  useEffect(() => {
    // Cache control logic (only version check, no legacy check)
    const checkBuildVersion = async () => {
      try {
        const metaVersion = document.querySelector('meta[name="build-version"]')?.content;
        const storedVersion = localStorage.getItem('app-version');
        
        if (metaVersion && metaVersion !== storedVersion) {
          if ('serviceWorker' in navigator) {
            const registrations = await navigator.serviceWorker.getRegistrations();
            for (let registration of registrations) {
              await registration.unregister();
            }
          }
          
          const cacheNames = await caches.keys();
          for (let name of cacheNames) {
            await caches.delete(name);
          }
          
          localStorage.setItem('app-version', metaVersion);
          window.location.reload(true);
        }
      } catch (error) {
        console.error('Error checking build version:', error);
      }
    };

    checkBuildVersion();
  }, []);

  return (
    <BrowserRouter>
      <div className="min-h-screen bg-gradient-to-b from-gray-900 to-black text-white">
        <Navigation onLoginClick={() => setShowLoginModal(true)} />
        <Routes>
          <Route path="/" element={<Navigate to="/homepage" replace />} />
          <Route path="/homepage" element={<HomePage />} />
        </Routes>
        {showLoginModal && (
          <LoginRegister 
            onClose={() => setShowLoginModal(false)}
          />
        )}
        {showConsentModal && (
          <CookieConsent
            initialSettings={cookieConsent}
            onSave={saveConsent}
            onAcceptAll={acceptAll}
          />
        )}
      </div>
    </BrowserRouter>
  );
};

export default App;