export const de = {
  nav: {
    title: 'Führ mich zum Schotter A.I.',
    home: 'Startseite',
    login: 'Einloggen'
  },
  hero: {
    title: 'Führ mich zum Schotter A.I.',
    subtitle: 'Die Zukunft der KI-gestützten Finanzanalyse in Echtzeit'
  },
  features: {
    title: 'Ihr Zugang zur modernen Finanzanalyse',
    realtime: 'Übernacht-Gap-Vorhersage',
    personalizedFeed: 'Erkennen Sie Kursbewegungen über Nacht durch die Kombination von KI und Nachrichtenanalyse.',
    ai: 'Echtzeit-Nachrichtenintegration',
    aiRecommendations: 'Passen Sie Ihre Strategien sofort an aktuelle Ereignisse und politische Entwicklungen an.',
    markets: 'Marktpsychologie nutzen',
    aiNewsAnalytics: 'Integrieren Sie Sentiment und psychologische Trends für fundiertere Handelsentscheidungen.',
    news: 'Anpassungsfähige KI-Modelle',
    influence: 'Nutzen Sie Machine Learning, das sich live an Markt- und Nachrichtenänderungen anpasst.'
},
  description: {
    intro: 'Unsere App vereint fortschrittliche KI-Technologie, umfassende Finanzanalysen und technische Indikatoren für maximale Präzision. Mit einem speziell entwickelten Chatbot haben Sie jederzeit die Möglichkeit, Ihre Fragen zu stellen und gezielte Ratschläge für Ihre Anlagestrategien zu erhalten.',
    points: [
      'Echtzeit-Kursdaten und Marktanalysen direkt abrufbar',
      'Komplette technische Analyse einschließlich MACD, RSI, Bollinger Bands und mehr',
      'Erfassung der Marktstimmung aus Nachrichten und Social Media durch KI',
      'Prädiktive Machine-Learning-Modelle für Trendanalysen und Prognosen',
      'Der KI-Chatbot unterstützt Sie bei Investitionsentscheidungen und der Strategieentwicklung'
    ],
    emphasis: 'Mit Führ mich zum Schotter A.I. sind Sie der Konkurrenz immer einen Schritt voraus und gewinnen wertvolle Einblicke durch Echtzeitprognosen und datenbasierte Analysen.'
  },
  pricing: {
    title: 'Unsere Abonnementpläne für jeden Bedarf',
    monthly: '/Monat',
    cta: 'Jetzt starten',
    freeTrial: {
      title: 'Kostenlose Testversion',
      price: '0 €',
      features: [
        'Basis-Zugang zu Marktdaten',
        'Eingeschränkte technische Analyse',
        'Tägliche Updates'
      ]
    },
    standardSubscription: {
      title: 'Standard-Abonnement',
      price: '100 €/Monat',
      features: [
        'Umfangreicher Zugang zu Marktdaten',
        'Echtzeit-Analyse und KI-basierte Empfehlungen',
        'Benachrichtigungen und personalisierte Berichte'
      ]
    },
    proSubscription: {
      title: 'Pro-Abonnement',
      price: '500 €/Monat',
      features: [
        'Voller Zugang zu Echtzeitdaten und prädiktiver Analyse',
        'Tiefgehende Stimmungs- und Trendanalysen',
        'Persönlicher Zugang zu einem KI-Berater rund um die Uhr'
      ]
    }
  },
  auth: {
    username: 'Benutzername',
    password: 'Passwort',
    email: 'E-Mail',
    confirmPassword: 'Passwort bestätigen',
    loginButton: 'Einloggen',
    registerButton: 'Registrieren',
    noAccount: 'Noch kein Konto? Registrieren',
    hasAccount: 'Bereits ein Konto? Einloggen',
    errors: {
      loginFailed: 'Login fehlgeschlagen: ',
      loginError: 'Ein Fehler ist beim Login aufgetreten',
      registrationSuccess: 'Registrierung erfolgreich. Bitte einloggen.',
      registrationFailed: 'Registrierung fehlgeschlagen: ',
      registrationError: 'Ein Fehler ist bei der Registrierung aufgetreten',
      passwordMatch: 'Die Passwörter stimmen nicht überein'
    }
  },
  cookies: {
    title: 'Cookie-Einstellungen',
    description: 'Diese Webseite verwendet Cookies, um Besuchern ein optimales Nutzererlebnis zu bieten. Bestimmte Inhalte von Drittanbietern werden nur angezeigt, wenn die entsprechende Option aktiviert ist. Die Datenverarbeitung kann dann auch in einem Drittland erfolgen. Weitere Informationen hierzu in der Datenschutzerklärung.',
    necessary: {
      title: 'Technisch notwendige Cookies',
      description: 'Für die Grundfunktionen der Website erforderlich'
    },
    analytics: {
      title: 'Analytische Cookies',
      description: 'Für die Analyse der Website-Nutzung'
    },
    thirdParty: {
      title: 'Drittanbieter-Inhalte',
      description: 'Für externe Inhalte und Funktionen'
    },
    acceptAll: 'Alle akzeptieren',
    save: 'Speichern'
  }
};