import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App.jsx';

// Early Cache Check before any rendering
const checkLegacyCache = async () => {
  const buildVersion = document.querySelector('meta[name="build-version"]')?.content;
  if (!localStorage.getItem('build-version') && buildVersion) {
    localStorage.clear();
    sessionStorage.clear();
    
    try {
      const cacheKeys = await caches.keys();
      await Promise.all(cacheKeys.map(key => caches.delete(key)));
      localStorage.setItem('build-version', buildVersion);
      window.location.reload(true);
      return true;
    } catch (error) {
      console.error('Cache clear failed:', error);
    }
  }
  return false;
};

// Render app only after cache check
const renderApp = async () => {
  const needsReload = await checkLegacyCache();
  if (!needsReload) {
    const container = document.getElementById('root');
    const root = createRoot(container);
    root.render(<App />);
  }
};

renderApp();