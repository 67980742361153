import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from '../hooks/useTranslation';
import { X, ChevronDown } from 'lucide-react';

// Importiere Flaggen
import FlagEN from '../assets/flags/flag_en.png';
import FlagDE from '../assets/flags/flag_de.png';
import FlagNL from '../assets/flags/flag_nl.png';

const CookieConsent = ({ onSave, onAcceptAll, initialSettings }) => {
  const { t, setLanguage, currentLang } = useTranslation();
  const [isLanguageMenuOpen, setIsLanguageMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const [settings, setSettings] = useState({
    necessary: true,
    analytics: initialSettings?.analytics || false,
    thirdParty: initialSettings?.thirdParty || false
  });

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsLanguageMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleToggle = (key) => {
    if (key === 'necessary') return;
    setSettings(prev => ({
      ...prev,
      [key]: !prev[key]
    }));
  };

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto" aria-labelledby="cookie-consent-title" role="dialog" aria-modal="true">
      <div className="fixed inset-0 bg-black/70 backdrop-blur-sm" />
      
      <div className="relative min-h-screen flex items-center justify-center p-4">
        <div className="relative bg-gradient-to-b from-gray-900 to-black rounded-lg shadow-xl max-w-lg w-full">
          <div className="p-6 space-y-6">
            <div className="flex justify-between items-center">
              <h2 id="cookie-consent-title" className="text-2xl font-bold text-white">{t.cookies.title}</h2>
              
              {/* Language Switcher */}
              <div className="relative" ref={menuRef}>
                <button 
                  type="button"
                  className="flex items-center space-x-1 hover:text-blue-400 transition-colors"
                  onClick={() => setIsLanguageMenuOpen(!isLanguageMenuOpen)}
                >
                  <span className="text-sm font-medium">{currentLang.toUpperCase()}</span>
                  <ChevronDown size={16} />
                </button>
                
                {isLanguageMenuOpen && (
                  <div className="absolute right-0 mt-2 w-48 bg-gray-800 rounded-md shadow-lg z-50 border border-gray-700">
                    <div className="py-1">
                      <button 
                        onClick={() => {
                          setLanguage('en');
                          setIsLanguageMenuOpen(false);
                        }} 
                        className="flex items-center px-4 py-2 text-gray-300 hover:bg-gray-700 text-left space-x-2 w-full"
                      >
                        <img src={FlagEN} alt="English" className="w-5 h-5" />
                        <span>English</span>
                      </button>
                      <button 
                        onClick={() => {
                          setLanguage('de');
                          setIsLanguageMenuOpen(false);
                        }} 
                        className="flex items-center px-4 py-2 text-gray-300 hover:bg-gray-700 text-left space-x-2 w-full"
                      >
                        <img src={FlagDE} alt="Deutsch" className="w-5 h-5" />
                        <span>Deutsch</span>
                      </button>
                      <button 
                        onClick={() => {
                          setLanguage('nl');
                          setIsLanguageMenuOpen(false);
                        }} 
                        className="flex items-center px-4 py-2 text-gray-300 hover:bg-gray-700 text-left space-x-2 w-full"
                      >
                        <img src={FlagNL} alt="Nederlands" className="w-5 h-5" />
                        <span>Nederlands</span>
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
            
            <p className="text-gray-300">{t.cookies.description}</p>
            
            <div className="space-y-4">
              {/* Necessary Cookies */}
              <div className="flex items-center justify-between p-4 bg-gray-800 rounded-lg">
                <div className="flex-1">
                  <h3 className="font-medium text-white">{t.cookies.necessary.title}</h3>
                  <p className="text-sm text-gray-400">{t.cookies.necessary.description}</p>
                </div>
                <button
                  className="relative w-12 h-6 bg-blue-600 rounded-full cursor-not-allowed"
                  disabled
                  aria-checked="true"
                  role="switch"
                >
                  <span className="absolute right-1 top-1 w-4 h-4 bg-white rounded-full transition-transform" />
                </button>
              </div>

              {/* Analytics Cookies */}
              <div className="flex items-center justify-between p-4 bg-gray-800 rounded-lg">
                <div className="flex-1">
                  <h3 className="font-medium text-white">{t.cookies.analytics.title}</h3>
                  <p className="text-sm text-gray-400">{t.cookies.analytics.description}</p>
                </div>
                <button
                  onClick={() => handleToggle('analytics')}
                  className={`relative w-12 h-6 rounded-full transition-colors ${settings.analytics ? 'bg-blue-600' : 'bg-gray-600'}`}
                  role="switch"
                  aria-checked={settings.analytics}
                >
                  <span 
                    className={`absolute top-1 w-4 h-4 bg-white rounded-full transition-transform ${
                      settings.analytics ? 'right-1' : 'left-1'
                    }`}
                  />
                </button>
              </div>

              {/* Third Party Cookies */}
              <div className="flex items-center justify-between p-4 bg-gray-800 rounded-lg">
                <div className="flex-1">
                  <h3 className="font-medium text-white">{t.cookies.thirdParty.title}</h3>
                  <p className="text-sm text-gray-400">{t.cookies.thirdParty.description}</p>
                </div>
                <button
                  onClick={() => handleToggle('thirdParty')}
                  className={`relative w-12 h-6 rounded-full transition-colors ${settings.thirdParty ? 'bg-blue-600' : 'bg-gray-600'}`}
                  role="switch"
                  aria-checked={settings.thirdParty}
                >
                  <span 
                    className={`absolute top-1 w-4 h-4 bg-white rounded-full transition-transform ${
                      settings.thirdParty ? 'right-1' : 'left-1'
                    }`}
                  />
                </button>
              </div>
            </div>
            
            <div className="flex gap-4 pt-4">
              <button
                onClick={() => onAcceptAll()}
                className="flex-1 bg-gradient-to-r from-blue-600 to-blue-800 text-white py-3 px-4 rounded-lg hover:from-blue-700 hover:to-blue-900 transition-colors"
              >
                {t.cookies.acceptAll}
              </button>
              <button
                onClick={() => onSave(settings)}
                className="flex-1 bg-gray-700 text-white py-3 px-4 rounded-lg hover:bg-gray-600 transition-colors"
              >
                {t.cookies.save}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookieConsent;