import { useState } from 'react';
import { de } from '../translations/de';
import { en } from '../translations/en';
import { nl } from '../translations/nl';

const translations = { de, en, nl };

export const useTranslation = () => {
  const [currentLang, setCurrentLang] = useState(() => {
    if (typeof window !== 'undefined') {
      const browserLang = navigator.language.slice(0, 2);
      return localStorage.getItem('language') || (['de', 'en', 'nl'].includes(browserLang) ? browserLang : 'en');
    }
    return 'en';
  });

  const setLanguage = (lang) => {
    if (typeof window !== 'undefined') {
      localStorage.setItem('language', lang);
      window.location.reload();
    }
    setCurrentLang(lang);
  };

  return {
    t: translations[currentLang],
    setLanguage,
    currentLang
  };
};

export default useTranslation;