import React from 'react';
import { useTranslation } from '../hooks/useTranslation.js';

const Hero = () => {
  const { t } = useTranslation();

  return (
    <div className="pt-24 pb-8 md:pt-32">
      <div className="max-w-7xl mx-auto px-4">
        <div className="text-center">
          <h1 className="text-4xl md:text-6xl font-bold mb-6 bg-gradient-to-r from-blue-400 to-purple-500 text-transparent bg-clip-text">
            {t.hero.title}
          </h1>
          <p className="text-xl md:text-2xl mb-8 text-gray-300">
            {t.hero.subtitle}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Hero;
