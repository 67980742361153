import React, { useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import Hero from '../components/Hero';
import { useTranslation } from '../hooks/useTranslation.js';
import LoginRegister from '../components/LoginRegister';

const chartData = [
  { name: 'Jan', value: 1000 },
  { name: 'Feb', value: 1200 },
  { name: 'Mar', value: 1100 },
  { name: 'Apr', value: 1400 },
  { name: 'May', value: 1300 },
  { name: 'Jun', value: 1600 }
];

const HomePage = () => {
  const { t } = useTranslation();
  const [selectedPlan, setSelectedPlan] = useState('freeTrial');
  const [showLoginModal, setShowLoginModal] = useState(false);

  // Funktion zum Öffnen des Login-Modal
  const handleSubscriptionClick = (plan) => {
    setSelectedPlan(plan);
    setShowLoginModal(true); // Zeigt das Login-Modal an
  };

  return (
    <div>
      <Hero />

      {/* Key Features */}
      <div className="py-16 bg-gray-900">
        <div className="max-w-7xl mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold mb-8">{t.features.title}</h2>
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
            <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
              <h3 className="text-xl font-semibold mb-4">{t.features.realtime}</h3>
              <p className="text-gray-400">{t.features.personalizedFeed}</p>
            </div>
            <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
              <h3 className="text-xl font-semibold mb-4">{t.features.ai}</h3>
              <p className="text-gray-400">{t.features.aiRecommendations}</p>
            </div>
            <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
              <h3 className="text-xl font-semibold mb-4">{t.features.markets}</h3> {/* Korrigierter Schlüssel */}
              <p className="text-gray-400">{t.features.aiNewsAnalytics}</p> {/* Korrigierter Schlüssel */}
            </div>
            <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
              <h3 className="text-xl font-semibold mb-4">{t.features.news}</h3>
              <p className="text-gray-400">{t.features.influence}</p>
            </div>
          </div>
        </div>
      </div>

      {/* Chart und erklärender Text */}
      <div className="py-16 bg-gray-900 bg-opacity-50">
        <div className="max-w-7xl mx-auto px-4 grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="h-64 md:h-96">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={chartData}>
                <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
                <XAxis dataKey="name" stroke="#9CA3AF" />
                <YAxis stroke="#9CA3AF" />
                <Tooltip 
                  contentStyle={{
                    backgroundColor: '#1F2937',
                    border: 'none',
                    borderRadius: '0.375rem'
                  }}
                />
                <Line 
                  type="monotone" 
                  dataKey="value" 
                  stroke="#3B82F6"
                  strokeWidth={2}
                  dot={{ fill: '#3B82F6' }}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>

          <div className="flex flex-col justify-center space-y-6">
            <h3 className="text-3xl font-bold text-center">{t.hero.title}</h3>
            <p className="text-lg text-gray-300 text-center">
              {t.description.intro} {t.description.emphasis}
            </p>
            <ul className="text-lg text-gray-300 space-y-4">
              {t.description.points.map((point, index) => (
                <li key={index} className="flex items-center space-x-2">
                  <span className="inline-block w-3 h-3 bg-blue-600 rounded-full"></span>
                  <span>{point}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      {/* Preispläne */}
      <div className="py-16 bg-gray-900">
        <div className="max-w-7xl mx-auto px-4 text-center">
          <h3 className="text-3xl font-bold mb-8">{t.pricing.title}</h3>
          <div className="grid md:grid-cols-3 gap-8">
            {['freeTrial', 'standardSubscription', 'proSubscription'].map((plan) => (
              <div
                key={plan}
                className={`bg-gray-800 rounded-lg p-8 ${
                  selectedPlan === plan ? 'border-2 border-blue-600' : ''
                }`}
                onClick={() => handleSubscriptionClick(plan)}
              >
                <h4 className="text-2xl font-bold mb-4">{t.pricing[plan].title}</h4>
                <p className="text-3xl font-bold mb-6">{t.pricing[plan].price}</p>
                <ul className="text-gray-400 space-y-2 mb-8">
                  {t.pricing[plan].features.map((feature, index) => (
                    <li key={index}>{feature}</li>
                  ))}
                </ul>
                <button
                  className="w-full bg-transparent py-2 rounded-md transition-colors" // Vollständig transparentes Styling
                  style={{ border: 'none', boxShadow: 'none', outline: 'none' }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSubscriptionClick(plan);
                  }}
                >
                  {t.pricing.cta}
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Login Modal */}
      {showLoginModal && (
        <LoginRegister onClose={() => setShowLoginModal(false)} />
      )}
    </div>
  );
};

export default HomePage;
