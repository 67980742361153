export const en = {
  nav: {
    title: 'Show Me The Money A.I.',
    home: 'Home',
    login: 'Login'
  },
  hero: {
    title: 'Show Me The Money A.I.',
    subtitle: 'The Future of AI-Powered Real-Time Financial Analysis'
  },
  features: {
    title: 'Your Gateway to Advanced Financial Analysis',
    realtime: 'Overnight Gap Prediction',
    personalizedFeed: 'Detect price movements overnight through the combination of AI and news analysis.',
    ai: 'Real-Time News Integration',
    aiRecommendations: 'Adapt your strategies instantly to current events and political developments.',
    markets: 'Leverage Market Psychology',
    aiNewsAnalytics: 'Incorporate sentiment and psychological trends for more informed trading decisions.',
    news: 'Adaptive AI Models',
    influence: 'Utilize machine learning that adapts live to market and news changes.'
},
  description: {
    intro: 'Our app leverages AI technology, financial expertise, and advanced technical indicators to deliver accurate, real-time insights. With an AI chatbot available anytime, you get professional advice tailored to your investment goals.',
    points: [
      'Real-time access to stock prices and market analysis',
      'Comprehensive technical indicators: MACD, RSI, Bollinger Bands, and more',
      'AI-driven sentiment analysis from news and social media sources',
      'Predictive models powered by machine learning for accurate trend forecasting',
      'AI chatbot support for personalized investment strategies and expert guidance'
    ],
    emphasis: 'Stay ahead with Show Me The Money A.I., combining data-driven insights with real-time predictions for a powerful investment tool.'
  },
  pricing: {
    title: 'Flexible Plans for Your Financial Goals',
    monthly: '/month',
    cta: 'Get Started',
    freeTrial: {
      title: 'Free Trial',
      price: '0 €',
      features: [
        'Basic market data access',
        'Limited technical analysis',
        'Daily market updates'
      ]
    },
    standardSubscription: {
      title: 'Standard Subscription',
      price: '100 €/month',
      features: [
        'Extensive market data and real-time analysis',
        'AI-based recommendations and notifications',
        'Personalized insights and reports'
      ]
    },
    proSubscription: {
      title: 'Pro Subscription',
      price: '500 €/month',
      features: [
        'Full access to predictive analytics and real-time data',
        'Advanced sentiment and trend analysis',
        '24/7 personal AI advisor access'
      ]
    }
  },
  auth: {
    username: 'Username',
    password: 'Password',
    email: 'Email',
    confirmPassword: 'Confirm Password',
    loginButton: 'Login',
    registerButton: 'Register',
    noAccount: 'Don\'t have an account? Register',
    hasAccount: 'Already have an account? Login',
    errors: {
      loginFailed: 'Login failed: ',
      loginError: 'An error occurred during login',
      registrationSuccess: 'Registration successful. Please login.',
      registrationFailed: 'Registration failed: ',
      registrationError: 'An error occurred during registration',
      passwordMatch: 'Passwords do not match'
    }
  },
  cookies: {
    title: 'Cookie Settings',
    description: 'This website uses cookies to provide visitors with an optimal user experience. Certain third-party content will only be displayed if the corresponding option is enabled. Data processing may then also take place in a third country. For more information, please refer to the privacy policy.',
    necessary: {
      title: 'Technically Necessary Cookies',
      description: 'Required for basic website functionality'
    },
    analytics: {
      title: 'Analytical Cookies',
      description: 'For website usage analysis'
    },
    thirdParty: {
      title: 'Third Party Content',
      description: 'For external content and features'
    },
    acceptAll: 'Accept All',
    save: 'Save'
  }
};