import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';
import { useTranslation } from '../hooks/useTranslation.js';

// Flaggenbilder importieren
import FlagEN from '../assets/flags/flag_en.png';
import FlagDE from '../assets/flags/flag_de.png';
import FlagNL from '../assets/flags/flag_nl.png';

const Navigation = ({ onLoginClick }) => {
  const { t, setLanguage, currentLang } = useTranslation();
  const [isLanguageMenuOpen, setIsLanguageMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsLanguageMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <nav className="fixed w-full bg-black bg-opacity-90 z-40 shadow-lg">
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex justify-between items-center h-16">
          <div className="flex items-center space-x-8">
            <Link to="/homepage" className="text-xl font-bold">Show Me The Money</Link>
            <div className="flex space-x-6">
              <Link to="/homepage" className="hover:text-blue-400 transition-colors">{t.nav.home}</Link>
            </div>
          </div>

          <div className="flex items-center space-x-6">
            <div className="relative" ref={menuRef}>
              <button 
                type="button"
                className="flex items-center space-x-1 hover:text-blue-400 transition-colors"
                onClick={() => setIsLanguageMenuOpen(!isLanguageMenuOpen)}
              >
                <span className="text-sm font-medium">{currentLang.toUpperCase()}</span>
                <ChevronDown size={16} />
              </button>
              {isLanguageMenuOpen && (
                <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-50">
                  <div className="py-1">
                    <button 
                      onClick={() => {
                        setLanguage('en');
                        setIsLanguageMenuOpen(false);
                      }} 
                      className="flex items-center px-4 py-2 text-gray-800 hover:bg-gray-100 text-left space-x-2"
                    >
                      <img src={FlagEN} alt="English" className="w-5 h-5" />
                      <span>English</span>
                    </button>
                    <button 
                      onClick={() => {
                        setLanguage('de');
                        setIsLanguageMenuOpen(false);
                      }} 
                      className="flex items-center px-4 py-2 text-gray-800 hover:bg-gray-100 text-left space-x-2"
                    >
                      <img src={FlagDE} alt="Deutsch" className="w-5 h-5" />
                      <span>Deutsch</span>
                    </button>
                    <button 
                      onClick={() => {
                        setLanguage('nl');
                        setIsLanguageMenuOpen(false);
                      }} 
                      className="flex items-center px-4 py-2 text-gray-800 hover:bg-gray-100 text-left space-x-2"
                    >
                      <img src={FlagNL} alt="Nederlands" className="w-5 h-5" />
                      <span>Nederlands</span>
                    </button>
                  </div>
                </div>
              )}
            </div>

            <button
              type="button"
              onClick={(e) => {
                e.preventDefault();
                onLoginClick();
              }}
              className="bg-blue-600 px-6 py-2 rounded-md hover:bg-blue-700 transition-colors"
            >
              {t.nav.login}
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
