import React, { useState } from 'react';
import { useTranslation } from '../hooks/useTranslation.js';
import { X } from 'lucide-react';

const LoginRegister = ({ onClose }) => {
  const [isLoginForm, setIsLoginForm] = useState(true);
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    confirmPassword: ''
  });
  const { t } = useTranslation();

  const setCookie = (name, value, days) => {
    let expires = '';
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = '; expires=' + date.toUTCString();
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/';
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const username = formData.get('username');
    const password = formData.get('password');

    try {
      const response = await fetch('/api/auth/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}`
      });

      const data = await response.json();
      
      if (response.status === 200 && data.access_token) {
        setCookie('auth_token', data.access_token, 1);
        window.location.href = '/';
      } else {
        alert(t.auth.errors.loginFailed + (data.detail || t.auth.errors.unknownError));
      }
    } catch (error) {
      console.error('Login error:', error);
      alert(t.auth.errors.loginError);
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const username = formData.get('username');
    const email = formData.get('email');
    const password = formData.get('password');
    const confirmPassword = formData.get('confirmPassword');

    if (password !== confirmPassword) {
      alert(t.auth.errors.passwordMatch);
      return;
    }

    try {
      const response = await fetch('/api/auth/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `username=${encodeURIComponent(username)}&email=${encodeURIComponent(email)}&password=${encodeURIComponent(password)}`
      });
      
      const data = await response.json();
      
      if (response.status === 200 && data.message === 'User created successfully') {
        alert(t.auth.errors.registrationSuccess);
        // Reset form data before switching
        setFormData({
          username: '',
          email: '',
          password: '',
          confirmPassword: ''
        });
        setIsLoginForm(true);
      } else {
        alert(t.auth.errors.registrationFailed + (data.detail || t.auth.errors.unknownError));
      }
    } catch (error) {
      console.error('Register error:', error);
      alert(t.auth.errors.registrationError);
    }
  };

  const switchForm = () => {
    setFormData({
      username: '',
      email: '',
      password: '',
      confirmPassword: ''
    });
    setIsLoginForm(!isLoginForm);
  };

  return (
    <div className="fixed inset-0 z-[9999]">
      <div className="fixed inset-0 bg-black/50" onClick={onClose} />
      
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <div className="relative bg-gradient-to-b from-gray-900 to-black rounded-lg shadow-xl w-full max-w-md">
          <div className="bg-gradient-to-r from-blue-600 to-blue-800 p-6 rounded-t-lg">
            <div className="flex justify-between items-center">
              <h2 className="text-2xl font-bold text-white">{t.nav.title}</h2>
              <button
                onClick={onClose}
                className="text-white hover:text-gray-200 transition-colors"
                type="button"
              >
                <X size={24} />
              </button>
            </div>
          </div>

          <div className="p-6 bg-gradient-to-b from-gray-900 to-black rounded-b-lg">
            {isLoginForm ? (
              <form onSubmit={handleLogin} className="space-y-4">
                <div>
                  <label className="block text-gray-300 mb-2" htmlFor="login-username">{t.auth.username}</label>
                  <input
                    id="login-username"
                    type="text"
                    name="username"
                    value={formData.username}
                    onChange={(e) => setFormData({...formData, username: e.target.value})}
                    required
                    className="w-full p-3 text-white bg-gray-800 border border-gray-700 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  />
                </div>
                <div>
                  <label className="block text-gray-300 mb-2" htmlFor="login-password">{t.auth.password}</label>
                  <input
                    id="login-password"
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={(e) => setFormData({...formData, password: e.target.value})}
                    required
                    className="w-full p-3 text-white bg-gray-800 border border-gray-700 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  />
                </div>
                <button
                  type="submit"
                  className="w-full bg-gradient-to-r from-blue-600 to-blue-800 text-white py-3 rounded-lg hover:from-blue-700 hover:to-blue-900 transition-colors"
                >
                  {t.auth.loginButton}
                </button>
                <div className="text-center mt-4">
                  <button
                    type="button"
                    onClick={switchForm}
                    className="text-blue-400 hover:text-blue-300"
                  >
                    {t.auth.noAccount}
                  </button>
                </div>
              </form>
            ) : (
              <form onSubmit={handleRegister} className="space-y-4">
                <div>
                  <label className="block text-gray-300 mb-2" htmlFor="register-username">{t.auth.username}</label>
                  <input
                    id="register-username"
                    type="text"
                    name="username"
                    value={formData.username}
                    onChange={(e) => setFormData({...formData, username: e.target.value})}
                    required
                    className="w-full p-3 text-white bg-gray-800 border border-gray-700 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  />
                </div>
                <div>
                  <label className="block text-gray-300 mb-2" htmlFor="register-email">{t.auth.email}</label>
                  <input
                    id="register-email"
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={(e) => setFormData({...formData, email: e.target.value})}
                    required
                    className="w-full p-3 text-white bg-gray-800 border border-gray-700 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  />
                </div>
                <div>
                  <label className="block text-gray-300 mb-2" htmlFor="register-password">{t.auth.password}</label>
                  <input
                    id="register-password"
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={(e) => setFormData({...formData, password: e.target.value})}
                    required
                    className="w-full p-3 text-white bg-gray-800 border border-gray-700 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  />
                </div>
                <div>
                  <label className="block text-gray-300 mb-2" htmlFor="register-confirm-password">{t.auth.confirmPassword}</label>
                  <input
                    id="register-confirm-password"
                    type="password"
                    name="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={(e) => setFormData({...formData, confirmPassword: e.target.value})}
                    required
                    className="w-full p-3 text-white bg-gray-800 border border-gray-700 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  />
                </div>
                <button
                  type="submit"
                  className="w-full bg-gradient-to-r from-blue-600 to-blue-800 text-white py-3 rounded-lg hover:from-blue-700 hover:to-blue-900 transition-colors"
                >
                  {t.auth.registerButton}
                </button>
                <div className="text-center mt-4">
                  <button
                    type="button"
                    onClick={switchForm}
                    className="text-blue-400 hover:text-blue-300"
                  >
                    {t.auth.hasAccount}
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginRegister;